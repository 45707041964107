<template>
  <div>
    <div class="wx-login">
      <div id="wxlogin"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    let script = document.createElement("script");
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    let login = document.getElementById("wxlogin");
    login && login.appendChild(script);
  },
  mounted() {
    let url = encodeURIComponent(location.origin+"/login?auth=wx");
    if(this.$route.query.bind) {
      url = encodeURIComponent(location.origin+"/mySelf/index?bind=wx");
    }
    this.$axios.get('/setting/public/social_login').then(res=>{
      if(res.code == 200){
        let data = res.data;
        console.log(data);
        if(data && data.length>0){
          for(let i=0,len = data.length;i<len;i++){
            let item = data[i];
            if(item.key == 'wechat'){
              if(item.value && item.value.is_on){
                this.$nextTick(() => {
                  new WxLogin({
                    id: "wxlogin",
                    appid: item.value.appId,
                    scope: "snsapi_login",
                    redirect_uri: url,
                    style: "block",
                    state: new Date().getTime,
                  });
                });
              }
            }
          }
        }
      }
    })
  },
};
</script>

<style scoped lang="scss">
#wxlogin {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
